<template>
  <div class="soft-order page">
    <h1 class="page-title">
      {{ $t('title') }}
    </h1>
    <transition name="fade" mode="out-in">
      <div v-if="loading && !list.length" class="soft-order__loading">
        <base-loader />
      </div>
      <div v-else-if="list.length" class="soft-order__content">
        <page-block :title="$t('choise')" size="bigger">
          <pricelist-block :list="list">
            <template v-slot:item="{ item }">
              <pricelist-card
                :id="item.id"
                :title="item.name"
                :desc="sanitize(item.description)"
                :price="item.cost"
                :active="current && current.id === item.id"
                @click="routeTo(item)"
              />
            </template>
          </pricelist-block>
        </page-block>
        <transition name="fade" mode="out-in">
          <router-view @change-period="changePeriod($event)"> </router-view>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import PricelistBlock from '@/components/Configurator/components/PricelistBlock';
import PricelistCard from '@/components/Configurator/components/PricelistCard';
import showErrorModal from '@/mixins/showErrorModal';
import { BillMgrPeriods } from '@/models/BillMgr/periods';

export default {
  name: 'SoftOrder',
  components: { PricelistBlock, PricelistCard },
  mixins: [showErrorModal],
  data() {
    return {
      configData: null,
      period: 1,
      currentPeriod: 1,
      cost: 0,
      costDetails: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      currentUrlPeriod: 1,
    };
  },
  computed: {
    list() {
      // this.addDataLayerPriceData(this.$store.state.moduleSoft.moduleSoftOrder.list, 'Soft');
      return this.$store.state.moduleSoft.moduleSoftOrder.list;
    },
    loading() {
      return this.$store.state.moduleSoft.moduleSoftOrder.loading;
    },
    current() {
      return this.$store.getters['moduleSoft/moduleSoftOrder/current'] || this.currentItem;
    },
    getCurrentPeriod() {
      return this.currentPeriod === this.period ? this.period : this.currentPeriod;
    },
    id() {
      if (this.$route.path === '/soft/order/plans') {
        return;
      } else {
        return this.$route.params.id ? +this.$route.params.id : this.current.id;
      }
    },
    currentItem() {
      return this.list.find(i => i.id === +this.id);
    },
    priceByPeriod() {
      return this.current.priceByPeriod.map(i => ({
        label: this.$tc(`period.${BillMgrPeriods[i.period]}_c`, i.period),
        value: i.period,
      }));
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === this.period);
    },
  },
  mounted() {
    this.currentUrlPeriod = +this.$route.params.period;
    if (!this.current) return this.fetchPricelist();
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleSoft/moduleSoftOrder/reset');
    next();
  },
  methods: {
    routeTo(item) {
      // this.clickToGtm(item, 'Soft');
      // this.viewDetailGtm(item, 'Soft');
      this.setCurrent(item);
      this.fetchParams(item, this.period);
      this.updateConfig();
      this.$router
        .push({
          name: 'softOrderDetails',
          params: { id: item.id, period: this.period },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            this.showErrorModal(err);
          }
        });
    },
    // api and logic methods
    fetchPricelist() {
      const params = {
        show_metadata: 'on',
        newface: 'on',
      };
      this.$store.dispatch('moduleSoft/moduleSoftOrder/fetchPricelist', params);
    },
    fetchCalc(data) {
      return this.$store.dispatch('moduleSoft/moduleSoftOrder/fetchCalc', data);
    },
    setCurrent(item) {
      this.$store.dispatch('moduleSoft/moduleSoftOrder/setCurrent', item.id);
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item.id;
      if (period) params.period = period;
      return this.$store.dispatch('moduleSoft/moduleSoftOrder/fetchParams', params);
    },
    // data methods
    // util methods
    updateConfig(data) {
      this.configData = { ...data };
    },
    getSpecs(item) {
      const list = [];
      if (!item.specs) return list;
      for (let prop of Object.keys(item.specs)) {
        list.push(this.$t(`specs.${prop}`, { n: item.specs[prop] }));
      }
      return list;
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    sanitize(html) {
      let tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      let res = tmp.textContent || tmp.innerText || '';
      res.replace('\u200B', ''); // zero width space
      res = res.trim();
      return res;
    },
    changePeriod(period) {
      this.currentPeriod = +period;
      this.$router.push({
        name: 'softOrderDetails',
        params: { id: this.current.id, period: period },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Покупка лицензии Soft",
    "choise": "Выберите лицензию",
    "additional": "Дополнительно",
    "summary": "Итого",
    "payfor": "при оплате за",
    "pay": "Оплатить",
    "order": "В корзину",
    "specs": {
      "disc": "{n} ГБ SSD",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Лицензия успешно приобретена, {num} списано с лицевого счета"
    },
    "needbalance": "Необходимо пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.soft-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
</style>
